import axios, { resHandle } from "@/plugins/axios";
/**
 * @description 数据表格列表页
 * @param vCom Vue组件
 * @param params 查询列表需要的参数
 */
const GetDataTables = (vCom: any, params: any, loading?: any): Promise<any> => {
    return new Promise((resolve, reject) => {
        axios.get("api/data/tables", params).then(({ data }) => {
            resHandle(vCom, data, loading).then(() => {
                resolve(data.data);
            }).catch((err) => {
                if (loading) {
                    loading.close();
                }
                reject(err);
            });
        }).catch((err) => {
            if (loading) {
                loading.close();
            }
            reject(err);
        });
    });
};
/**
 * @description 导出数据表格
 * @param vCom Vue组件
 */
const ExportTable = (vCom: any, params: any, loading?: any): Promise<any> => {
    return new Promise((resolve, reject) => {
        axios.post("api/data/export/table", params).then(({ data }) => {
            resHandle(vCom, data, loading).then(() => {
                resolve(data.data);
            }).catch((err) => {
                if (loading) {
                    loading.close();
                }
                reject(err);
            });
        }).catch((err) => {
            if (loading) {
                loading.close();
            }
            reject(err);
        });
    });
};
/**
 * @description 删除数据表格
 * @param vCom Vue组件
 */
const DeleteTable = (vCom: any, params: any, loading?: any): Promise<any> => {
    return new Promise((resolve, reject) => {
        axios.delete("api/data/table", params).then(({ data }) => {
            resHandle(vCom, data, loading).then(() => {
                resolve(data.data);
            }).catch((err) => {
                if (loading) {
                    loading.close();
                }
                reject(err);
            });
        }).catch((err) => {
            if (loading) {
                loading.close();
            }
            reject(err);
        });
    });
};
/**
 * @description 预览数据表格
 * @param vCom Vue组件
 * @param tableId 表格id
 */
const GetDataTableReview = (vCom: any, tableId: any, loading?: any): Promise<any> => {
    const params: any = {
        params: {
            table_id: tableId
        }
    };
    return new Promise((resolve, reject) => {
        axios.get("api/data/table", params).then(({ data }) => {
            resHandle(vCom, data, loading).then(() => {
                resolve(data.data);
            }).catch((err) => {
                if (loading) {
                    loading.close();
                }
                reject(err);
            });
        }).catch((err) => {
            if (loading) {
                loading.close();
            }
            reject(err);
        });
    });
};
export { GetDataTables, ExportTable, DeleteTable, GetDataTableReview }