





























import { Component, Vue, Prop } from "vue-property-decorator";
@Component({})
export default class PaginationCmp extends Vue {
  @Prop()
  private totalNum: any;
  @Prop()
  private currentPage: any;
  @Prop()
  private ifSmall: any;
  @Prop()
  private showPageSize: any;
  @Prop()
  private pageSize: any;
  @Prop()
  private pageSizes: any;
  get size() {
    return this.pageSize;
  }
  set size(val) {
    // this.$emit("current-page-change", val);
  }
  private handleCurrentChange(val: any) {
    this.$emit("current-page-change", val);
  }
  private handleSizeChange(val: any) {
    this.$emit("current-size-change", val);
  }
}
