




































































































































import { Component, Vue, Prop } from "vue-property-decorator";
import MainHeader from "@/components/main-header/main-header.vue";
import Pagination from "@/components/paginaiton.vue";
import PreviewDialog from "../components/previewDialog.vue";
import { GetDataTables, ExportTable, DeleteTable } from "@/request/export";
@Component({
  components: {
    MainHeader,
    Pagination,
    PreviewDialog,
  },
})
export default class Name extends Vue {
  private filters: any = {
    search: "",
    totalPage: 0,
    totalCount: 0,
    pageCount: 10,
    currentPage: 1,
  };
  private ifShowPreview: boolean = false;
  private tempTableId: any = "";
  private messages: any[] = [];
  /**
   * @description 搜索
   */
  private search() {
    this.filters.currentPage = 1;
    this.fetchList();
  }
  /**
   * @description 导出
   */
  private exportOne(table: any) {
    const loading = this.$loading({
      lock: true,
      text: "导出中",
      spinner: "el-icon-loading",
    });
    const params: any = {
      table_id: table.table_id,
    };
    ExportTable(this, params, loading)
      .then((data: any) => {
        loading.close();
        window.open(
          window.API_ROOT + "api/wajue/download?filename=" + data.file_name
        );
      })
      .catch((err) => {
        loading.close();
      });
  }
  /**
   * @description 删除
   */
  private deleteOne(table: any) {
    this.$confirm("确定要删除吗？", "删除", {
      customClass: "commonConfirm",
    }).then(() => {
      const params: any = {
        data: {
          table_id: table.table_id,
        },
      };
      DeleteTable(this, params).then((data: any) => {
        this.$message.success("删除成功");
        this.fetchList();
      });
    });
  }
  /**
   * @description 查看
   */
  private checkOne(table: any) {
    this.tempTableId = table.table_id;
    this.ifShowPreview = true;
  }
  /**
   * @description 处理翻页器的当前页变化
   */
  private handleCurrentPageChange(val: any) {
    this.filters.currentPage = val;
    this.fetchList();
  }
  /**
   * @description 处理翻页器的每页条数变化
   */
  private handleCurrentSizeChange(val: any) {
    this.filters.currentPage = 1;
    this.filters.pageCount = val;
    this.fetchList();
  }
  /**
   * @description 获取列表
   */
  private fetchList() {
    const loading = this.$loading({
      lock: true,
      text: "加载中……",
      spinner: "el-icon-loading",
      customClass: "loadClass",
      background: "rgba(255, 255, 255, 0.7)",
    });
    const params: any = {
      params: {
        current_page: this.filters.currentPage,
        page_count: this.filters.pageCount,
        sort_field: "创建时间",
        sort_value: 0,
        search: this.filters.search,
      },
    };
    GetDataTables(this, params)
      .then((data: any) => {
        this.messages = data.tables;
        this.filters.totalCount = data.total_count;
        this.filters.totalPage = data.total_page;
      })
      .finally(() => {
        loading.close();
      });
  }
  /**
   * @description 初始化
   */
  private mounted() {
    this.fetchList();
  }
}
